export default {
  en: {
    home: 'Home',
    HospitalName : 'Hospital Name',
    CreateNewLead : 'Create New Lead',
    Region : 'Region',
    LeadName : 'Lead Name',
    BusinessOpportunitytype : 'Business Opportunity Type',
    CustomerStatus : 'Customer Status',
    WhenDoesTheCustomerNeedTheSystem : 'System is needed on ?',
    AdditionalComment : "Additional Comment Don't include any sensitive/personal information, and to abide by the PDPL",
    ContactPerson : 'Contact Person',
    WhatIsTheLeadComposedOf : 'What is the lead composed of?',
    new : 'New',
    Replacement : 'Replacement',
    Siemens : 'Siemens',
    Competitor : 'Competitor',
    NewCustomer : 'NewCustomer',
    AddSystem : 'Add System',
    Submit : 'Submit',
    DevicesLeads : 'Devices/Needs',
    ThisFieldisRequired: 'This field is required',
    LeadX : 'LeadX',
    contactUS : 'Contact us',
    Logout : "Logout",
    WelcometoLeadX : "Welcome to LeadX", 
    loginSentance : "LeadX is a lead generation program for all employees aimed to open the door for all Healthineers to participate in generating business and ensure opportunities are never missed !",
    emptyLeads : "You don’t have any leads yet !",
    emptyLeads2 : " Create new leads and enjoy tracking its status",
    noLeadsYet : "No Leads Yet !",
    leadStatus : "Lead Status",
    leadDetails : "Lead Details",
    leadID : "Lead ID",
    Information: "Information",
    EmptyNotification : "Empty Notification !",
    noNotifications : "It seems, No notification in your inbox. start Create leads now.",
    New : "New",
    VerifiedbyDCE: " VerifiedbyDCE",
    Confirmed : "Confirmed",
    Approved : "Approved",
    Promoted : "Promoted",
    OrderBooked : "Order Booked",
    Rejected : "Rejected",
    rejectedMsg : "This request has been rejected beacuse the lead out of our supported areas",
    LeadDate : "Lead Date",
    HospitalRegion : "Hospital Region",
    Details : "Details",
    dateNeedSystem : "System is needed on",
    Note : "Note",
    Device : "Device",
    fieldMax40: "This field maximum length is 40",
    seeMore : "See More",
    Notifications : "Notifications",
    Rewarded : "Rewarded",
    rewardMsg1 :"You have been Rewarded by",
    rewardMsg2 : "for this lead your reward will be added to your next payroll",
    ID :"ID",
    needDevice : "Device / Need",
    optional : "(Optional)",
    reason : "Reason",
    chatWithUs : "Chat with us",
    chatHere: "You can chat with us here",
    sendEmail : "Send Email",
    sendQorProblem : "Send your question or problem",
    customerService : "Customer Service",
    city: "city",
    Sector : "Sector",
    SAR : "SAR"

  }
}
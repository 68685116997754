export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';
export const GET_CURRENT_LANG = 'GET_CURRENT_LANG';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA = 'FETCH_DATA';
export const STORE_LEADS_LIST = "STORE_LEADS_LIST";
export const GET_LEAD_DETAILS = "GET_LEAD_DETAILS"
export const STORE_BUISINESS_OPORTUNITIES = "STORE_BUISINESS_OPORTUNITIES"
export const STORE_CUSTOMER_STATUS = "STORE_CUSTOMER_STATUS"
export const STORE_DEVICES = "STORE_DEVICES"
export const STORE_REGIONS= "STORE_REGIONS"
export const STORE_SECTORS = "STORE_SECTORS";